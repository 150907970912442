import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const baseAppSettings: IPartnerAppSettings = {
  cdnBaseUrl: 'https://stcspsit.z22.web.core.windows.net/portfolio-ui',
  serviceEndPoints: [
    {
      clientId: 'api://f77695f8-032a-43b9-9263-418c7efa2c56',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/csp-api',
    },
    {
      clientId: 'api://f77695f8-032a-43b9-9263-418c7efa2c56',
      serviceEndPoint: 'https://fd-csp-sit-h7f5bmcpfahwfwfr.z01.azurefd.net/csp-api',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/'
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://servicesuat.microsoft.com/'
    },
    {
      clientId: 'api://f77695f8-032a-43b9-9263-418c7efa2c56',
      serviceEndPoint: 'https://fd-csp-powerpoint-export-sit-dtb2abg9a3a5e5dg.z01.azurefd.net/api',
    }
  ],
  PortfolioAppConfiguration: {
    'portfolioServiceBaseUri': 'https://professionalservicesint.microsoft.com/csp-api',
    'RMSericeBaseUri': 'https://professionalservicesint.microsoft.com/rmsvcsit/',
    'GetAssignedCustomers': '/api/v1/accounts',
    'GetAccountById': '/api/v1/accounts/%s',
    'GetAccountByTpidAndSearchText': '/api/v1/accounts/heirarchy?tpid=%s&searchText=%s&accountPlanId=%s',
    'SavePreferredCustomers': '/api/v1/preferredAccounts',
    'DeletePreferredCustomers': '/api/v1/preferredAccounts/%s',
    'SearchAssignedCustomers': '/api/v1/accounts?searchText=%s',
    'GetOpportunitiesActivities': '/api/v1/accounts/%s/opportunities',
    'GetAccountSuccessPlans': '/api/v1/successplans/accounts/%s',
    'GetObjectivesPriorities': '/api/v1/accounts/%s/priorities?cspId=%s',
    'GetCspDetails': '/api/v1/successplans/%s',
    'GetCspRaidHighlights': '/api/v1/successplans/%s/raids',
    'GetLinkedFastTrackProjects': '/api/v1/fasttrack?cspId=%s',
    'GetUnlinkedFastTrackProjects': '/api/v1/fasttrack?tpId=%s',
    'GetUnlinkedFastTrackProjectsByTPID': '/api/v1/accounts/%s/fasttrackv2?projectType=%s',
    'GetUnlinkedConsultingProjects': '/api/v1/accounts/%s/consultingprojects',
    'GetCspUnmappedPriorities': '/api/v1/accounts/%s/priorities',
    'graphApiBaseUri': 'https://graph.microsoft.com/',
    'subscriptionKey': '92af6c717f5b4f2c9a41db17e17cc947',
    'GetCspLinkedPriorities': '/api/v1/successplans/%s/priorities',
    'EditCspDetails': '/api/v1/successplans/%s',
    'GetCustomerSponsors': '/api/v1/contacts/customersponsors?searchText=%s&accountPlanId=%s&tpid=%s',
    'GetMsftUsers': `v1.0/users?$filter=endswith(userPrincipalName,'@microsoft.com')&$search="displayname:%s"&$orderby=displayName`,
    'GetMsftUsersByNameOrAlias': `v1.0/users?$filter=endswith(userPrincipalName,'@microsoft.com')&$search="displayName:%s" OR "userPrincipalName:%s"&$orderby=displayName`,
    'CreateCsp': '/api/v1/successplans',
    'GetUnmappedPriorities': '/api/v1/accounts/%s/priorities',
    'ConsultingProjectInVirtuoso': 'https://stage.virtuoso.microsoft.com/ProjectDetail?ProjectId=%s',
    'GetOpportunityDetails': '/api/v1/opportunities/%s',
    'FastTrackProjectInMSX': 'https://msit.powerbi.com/groups/77f20a83-8eb3-4cc1-9bc3-30eeb05c8a7b/reports/656dd9bc-49e5-464e-a4db-949efa5d5f2d/ReportSectionb6947c090d130ba80d40',
    'GetCspRaidLogs': '/api/v1/successplans/%s/raids',
    'RMAPIMSubscriptionKey': '01e62d14a5f04161818c5ef9ee321875',
    'RMCustomersGraphQLAPI': '/graphql',
    'RMExRaidByTpid': '/api/v1/raids?tpId=%s',
    'RMExProjectsByTpid': '/api/v1/supportprojects?tpId=%s',
    'RMExProjectsByIds': '/api/v1/supportprojects',
    'RMExProjectsRaidsByProjectId': '/api/v1/supportprojects/%s/raids',
    'GetObjectiveDetails': '/api/v1/objectives/%s',
    'GetLinkedOpportunities': '/api/v1/successplans/%s/opportunities',
    'UnlinkActivitiesFromPriorities': '/api/v1/successplans/%s/priorities/entities',
    'EditCspRaidLog': '/api/v1/successplans/%s/raids/%s?customProjectId=%s',
    'DeleteCspRaidLog': '/api/v1/successplans/%s/raids/%s?customProjectId=%s',
    'GetLinkedUatAndSpRaids': '/api/v1/successplans/%s/linkeduatandspraids?tpId=%s',
    'CreateContactInMsxLink': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=contact',
    'ObjectiveDetailsInMsx': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_customerobjective&id=%s',
    'GetFastTrackDetails': '/api/v1/fasttrack/%s',
    'GetLinkedFastTrackV2Projects': '/api/v1/customersuccessplans/%s/FastTrackV2?projectType=%s',
    'GetFastTrackV2Details': '/api/v1/fasttrackv2?projectType=%s&ids=%s',
    'PriorityDetailsInMsx': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_accountplanpriority&id=%s',
    'GetConsultingProjectDetails': '/api/v1/consultingprojects',
    'GetOpportunityRaidHighlights': '/api/v1/unifiedactiontracker/opportunities/actions',
    'GetMsftUserByAlias': 'v1.0/users/%s',
    'GetLinkedConsultingProjects': '/api/v1/successplans/%s/consultingprojects',
    'LinkEntitesToPriority': '/api/v1/successplans/%s/priorities/entities',
    'GetCspExportToPPT': '/api/v1/successplans/%s/export/ppt',
    'GetSupportProjectsActivities': '/api/v1/successplans/%s/supportprojects',
    'CspGraphQLBaseURL': 'https://fd-csp-sit-h7f5bmcpfahwfwfr.z01.azurefd.net/csp-api',
    'CspGraphQLAPI': '/graphql',
    'GetMsftUserPresenceById': 'beta/users/%s/presence',
    'MsGraphBatch': 'v1.0/$batch',
    'GetOpportunityMilestones': '/api/v1/opportunities/%s/milestones',
    'OpportunityDetailsInMsx': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=opportunity&id=%s',
    'EcifDetailsInOneAskRequestPortal': 'https://oneaskuat.azurewebsites.net/requests/all',
    'MilestoneDetailsInMsx': 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=msp_engagementmilestone&id=%s',
    'GetRaidLogsByTpidFromUAT': '/api/v1/unifiedactiontracker/actions?tpid=%s',
    'GetRaidlogsForCspsByAccountId': '/api/v1/accounts/%s/raids',
    'GetUnparentedPriorityOpportunities': '/api/v1/priorities/%s/opportunities',
    'GetMilestonesByOpportunityIds': '/api/v1/opportunities/milestones',
    'GetPriorityDetails': '/api/v1/priorities/%s',
    'GetCspFavorites': '/api/v1/user/favouritesuccessplans?expandCsps=%s',
    'RemoveCspFavorite': '/api/v1/user/favouritesuccessplans/%s',
    'AddCspFavorite': '/api/v1/user/favouritesuccessplans/%s',
    'UnifiedActionTrackerDeepLink': 'https://uat-int1-webui-westus.azurewebsites.net/action?id=%s',
    'flightingServicePath': '/api/v1/featureflags/Evaluate?featureNames=%s',
    'featureFlighting': {
      'BaseUrl': 'https://servicesuat.microsoft.com',
      'ResourceId': '6f40053e-5319-40e5-a90b-6f714506d96d',
      'RoleGroupId': '2',
      'TenantId': 'ES',
      'Environment': 'dev',
      'Application': 'Customer Success Portfolio',
    },
    'GetDeliveryDetails': '/api/v1/deliveries/details',
    'GetDeliveryRequestIds': '/api/v1/supportprojects/deliveries/basic',
    'SupportDeliveryEsxpLink': 'https://servicesint.azurewebsites.net/#/supportdelivery/requestdetails/%s',
    'CustomerSuccessDeliveryReviews': '/api/v1/%s/customersuccessdeliveryreviews',
    'GetCsdrAgreements': '/api/v1/agreements?tpid=%s',
    'GetCustomProjectsByCspId': '/api/v1/successplans/%s/customprojects',
    'GetCustomProjectByCustomProjectId': '/api/v1/successplans/%s/customprojects/%s',
    'EditOrDeleteCustomProjectByCustomProjectId': '/api/v1/successplans/%s/customprojects/%s',
    'GetCustomProjectRaidsByTpId': '/api/v1/accounts/%s/customprojectraids',
    'CspCsdrFunctionBaseUri': 'https://fd-csp-powerpoint-export-sit-dtb2abg9a3a5e5dg.z01.azurefd.net/api',
    'CspCsdrFunctionApiPath': '/csdr-export',
    'GetCspUnmappedPrioritiesByAccountPlan': '/api/v1/accounts/%s/priorities?accountPlanId=%s',
    'GetObjectivesPrioritiesByAccountPlan': '/api/v1/accounts/%s/priorities?cspId=%s&accountPlanId=%s',
    'GetUnmappedPrioritiesByAccountPlan': '/api/v1/accounts/%s/priorities?accountPlanId=%s',
    'MoveCspActivities': '/api/v1/successplans/priorities/entities/move',
    'GetCspSharedPriorities': '/api/v1/sharedprioritysuccessplans/%s/activities',
    'GetSharedPriorityCsps': '/api/v1/sharedprioritysuccessplans/%s',
    'GetOpportunitiesByPriorityIds': '/api/v1/priorities/opportunities',
    'GetCustomProjectsByIdList': '/api/v1/successplans/customprojects/tpid/%s',
    'GetFastTrackProjectsByIdList': '/api/v1/fasttrack/%s/projects',
    'GetConsultingProjectsByIdList': '/api/v1/consultingprojects/%s/projects',
    'GetPrioritiesForCspCreation': '/api/v1/accountinsights/%s/prioritiesforcspcreation?accountPlanId=%s&msSalesId=%s',
    'CreateCspThroughDigitalSignal': '/api/v1/successplans/viadigitalsignal',
    'RemovePriorityViaSnooze': '/api/v1/digitalsignals/snoozedigitalsignal',
    'GetPriorityDataByPriorityId': '/api/v1/priorities/%s',
    'GetSupportProjectsByCspId': '/api/v1/customersuccessplans/%s/supportprojects',
    //Ecif
    'GetUnlinkedEcifProjectsByTpId': '/api/v1/accounts/%s/ecif',
    'GetLinkedEcifProjectsByCspId': '/api/v1/customersuccessplans/%s/ecif',
    'GetEcifSowMilestones': '/api/v1/accounts/%s/sowmilestones',
    //Consumption
    'GetAcrConsumption': '/api/v1/successplans/%s/consumption?accountId=%s',
    //Support Needs
    'GetSupportNeeds': '/api/v1/supportneeds/mssalesid/%s',
    'CspDetailsInEsxp': 'https://servicesint.azurewebsites.net/#/customers/%s/csps/%s',
    'SupportProjectInRmexV2': 'https://servicesint.azurewebsites.net/#/rm/customer/%s/supportproject/%s',
    //consumption gap
    'GetConsumptionGapForMsSalesId': '/api/v1/consumption/gap/%s',
    'GetMilestonesForVbdCreation': '/api/v1/consumption/vbdMilestones?cspId=%s',
    'GetRmexCustomersForUserByTpid': '/api/v1/customers/%s',
    'GetCspAITitleDescription' : '/api/v1/successplans/summarization',
    //Milestones details
    'GetMilestonesDetails': '/api/v1/milestones/%s',
  }
};
