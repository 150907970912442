import {
  IAppService,
  IStateConfig,
  IFxpAppContext,
  IServiceEndPoints,
  IRouteInfo,
  IPartnerBundle
} from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class CustomerSuccessPortfolioApplication_routes implements IAppService {

  /* Implement the IAppServiceWithSharedComponent and follow the below code to expose component as shared component
  getSharedComponentsInfo() {
    const helloWorldSharedComponent: ISharedComponentConfig = {
      component: HelloWorldComponent,
      componentFramework: 'React',
      componentDescription: "This is a hello world shared component.",
      componentName: "customersuccessportfolio-hellowordcomponent",
      exposeAsExternalComponents: true
    };

    return {
      components: [helloWorldSharedComponent],
      disableSharing: false,
      sharedBundles: [],
      appName: "CustomerSuccessPortfolio"
    }
  }
  */

  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    const createCspRoute: IStateConfig = {
      name: 'createcsp',
      url: '/customers/:customerid/csps/create',
generatedBundle: 'dba364ee8566-CreateCSPWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'Create CSP',
        breadcrumbText: 'Create CSP',
        pageTitle: 'Create CSP',
      },
    };

    const cspDetailsRoute: IStateConfig = {
      name: 'cspdetails',
      url: '/customers/:customerid/csps/:cspid',
generatedBundle: 'dba364ee8566-CspDetailsWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'CSP details',
        breadcrumbText: 'CSP details',
        pageTitle: 'CSP details',
      },
    };

    const portfolioRoute: IStateConfig = {
      name: 'customerportfolio',
      url: '/customerportfolio',
generatedBundle: 'dba364ee8566-Customers-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'myportfolio',
        breadcrumbText: 'My Portfolio',
        pageTitle: 'My Portfolio',
      },
    };

    const portfolioManagerRoute: IStateConfig = {
      name: 'portfoliomanager',
      url: '/customers/:customerid/portfoliomanager',
generatedBundle: 'dba364ee8566-PortfolioDetailsWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'Portfolio Details',
        breadcrumbText: 'Portfolio Details',
        pageTitle: 'Portfolio Details'
      }
    };

    const activitiesProjectsRoute: IStateConfig = {
      name: 'activitiesprojects',
      url: '/customers/:customerid/:tpid/csps/:cspid/activities',
generatedBundle: 'dba364ee8566-CSPActivitiesProjectsWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'activities',
        breadcrumbText: 'Activities & Projects',
        pageTitle: 'Activities & Projects',
      },
    };

    const consumptionRoute: IStateConfig = {
      name: 'consumption',
      url: '/customers/:customerid/:tpid/csps/:cspid/consumption',
generatedBundle: 'dba364ee8566-CSPConsumptionCompWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'consumption',
        breadcrumbText: 'Consumption',
        pageTitle: 'Consumption',
      },
    };

    const raidLogRoute: IStateConfig = {
      name: 'customerraidlog',
      url: '/customers/:customerid/raidlog',
generatedBundle: 'dba364ee8566-RaidLogWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'raidlog',
        breadcrumbText: 'Raid Log',
        pageTitle: 'Raid Log',
      },
    };

    const cspRaidLogRoute: IStateConfig = {
      name: 'cspraidlog',
      url: '/customers/:customerid/csps/:cspid/raidlogs',
generatedBundle: 'dba364ee8566-CSPRaidLogWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'cspraidlog',
        breadcrumbText: 'CSP Raid Log',
        pageTitle: 'CSP Raid Log',
      },
    };

    const customerCspRoute: IStateConfig = {
      name: 'customercsp',
      url: '/customers/:customerid/successplans',
generatedBundle: 'dba364ee8566-AccountCspWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'Customer CSP',
        breadcrumbText: 'Customer CSP',
        pageTitle: 'Customer CSP',
      },
    };

    const cspUnmappedPrioritiesRoute: IStateConfig = {
      name: 'cspunmappedpriorities',
      url: '/customers/:customerid/cspunmappedpriorities',
generatedBundle: 'dba364ee8566-CSPUnmappedPrioritiesWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'cspunmappedpriorities',
        breadcrumbText: 'CSP Unmapped Priorities',
        pageTitle: 'Unmapped Priorities',
      },
    };

    const milestonesDetailsRoute: IStateConfig = {
      name: 'milestoneswrapper',
      url: '/customers/:customerid/milestones/:milestoneid/details',
generatedBundle: 'dba364ee8566-MilestonesDetailsWrapper-Bundle',
      componentFramework: 'React',
      data: {
        headerName: 'milestoneswrapper',
        breadcrumbText: 'Milestones Wrapper',
        pageTitle: 'Milestones Wrapper',
      },
    };

    const routeInfo: IRouteInfo = {
      applicationName: "CustomerSuccessPortfolio",
      sharedBundles: [],
      routes: [
        portfolioRoute,
        createCspRoute,
        raidLogRoute,
        portfolioManagerRoute,
        cspRaidLogRoute,
        customerCspRoute,
        activitiesProjectsRoute,
        cspUnmappedPrioritiesRoute,
        cspDetailsRoute,
        consumptionRoute,
        milestonesDetailsRoute
      ],
    };

    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundle: IPartnerBundle = {
      name: 'CSP-Bundle',
      files: [
        `${baseUrl}/vendor.bundle.js`,
        `${baseUrl}/styles.bundle.js`,
        `${baseUrl}/main.bundle.js`,
      ],
      sequentialLoading: true,
    };

    return [bundle];
  }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: 'dba364ee8566-CreateCSPWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CreateCSPWrapper.vendor.bundle.js',
                    baseUrl + '/CreateCSPWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-CspDetailsWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CspDetailsWrapper.vendor.bundle.js',
                    baseUrl + '/CspDetailsWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-Customers-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/Customers.vendor.bundle.js',
                    baseUrl + '/Customers.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-PortfolioDetailsWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/PortfolioDetailsWrapper.vendor.bundle.js',
                    baseUrl + '/PortfolioDetailsWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-CSPActivitiesProjectsWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CSPActivitiesProjectsWrapper.vendor.bundle.js',
                    baseUrl + '/CSPActivitiesProjectsWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-CSPConsumptionCompWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CSPConsumptionCompWrapper.vendor.bundle.js',
                    baseUrl + '/CSPConsumptionCompWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-RaidLogWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RaidLogWrapper.vendor.bundle.js',
                    baseUrl + '/RaidLogWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-CSPRaidLogWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CSPRaidLogWrapper.vendor.bundle.js',
                    baseUrl + '/CSPRaidLogWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-AccountCspWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AccountCspWrapper.vendor.bundle.js',
                    baseUrl + '/AccountCspWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-CSPUnmappedPrioritiesWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CSPUnmappedPrioritiesWrapper.vendor.bundle.js',
                    baseUrl + '/CSPUnmappedPrioritiesWrapper.bundle.js'
                ],
                sequentialLoading: true
            },
    {
                name: 'dba364ee8566-MilestonesDetailsWrapper-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/MilestonesDetailsWrapper.vendor.bundle.js',
                    baseUrl + '/MilestonesDetailsWrapper.bundle.js'
                ],
                sequentialLoading: true
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(CustomerSuccessPortfolioApplication_routes, 'CustomerSuccessPortfolio');
